var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" }
            },
            [
              _c("pb-user-mode-selector"),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "pb-btn",
                    {
                      attrs: {
                        color: "primary",
                        label: _vm.$t("addLabel"),
                        icon: ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.edit(null)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-plus")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  !_vm.editMode
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "close-on-click": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var onMenu = ref.on
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t("actionsLabel"),
                                            icon: ""
                                          }
                                        },
                                        Object.assign({}, onMenu)
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "fal fa-ellipsis-stroke-vertical"
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1297348514
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { on: { click: _vm.toggleEditMode } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { text: "", color: "primary" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fal fa-pen-to-square")
                                      ]),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(_vm._s(_vm.$t("manageLabel")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "pb-btn",
                        {
                          attrs: {
                            color: "primary",
                            label: _vm.$t("doneLabel"),
                            icon: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.toggleEditMode.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "done-icon" }, [
                            _vm._v("fal fa-pen-slash")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-fade-transition",
                [
                  _vm.hasSelectedRows
                    ? _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "auto" } },
                        [
                          _c(
                            "pb-btn",
                            {
                              attrs: {
                                color: "error",
                                label: _vm.$t("deleteLabel"),
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteSelectedSchoolYears.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }