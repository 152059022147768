
import { Component } from 'vue-property-decorator';
import SchoolYearsMixin from '@/mixins/school-years-mixin';
import SchoolYearsToolbar from './SchoolYearsToolbar.vue';
@Component({
  mixins: [SchoolYearsMixin],
  components: {
    SchoolYearsToolbar
  }
})
export default class SchoolYearsBottomToolbar extends SchoolYearsToolbar {
}
